// Helpers
import { Component } from "./helpers/component";
import { Utils } from "./helpers/utils";
import { Lazyload } from "./helpers/lazyload";

// Importing components
import { Slider } from "../../components/slider/slider";

// Registering components
Component.register('Slider', Slider);

const namespace = 'zaux';

// On DOM ready
document.addEventListener('DOMContentLoaded', () => {

  Utils.credits();

  Lazyload.init();
  
  Component.initAll();

  if (window['objectFitImages'] !== undefined) {
    objectFitImages();
  }

});