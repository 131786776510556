export class Component {

  static library = {};
  static classNameSkip = 'js-component--skip';
  static classNameLoaded = 'js-component--loaded';

  static initAll() {

    document.querySelectorAll(`[data-js-component]:not(.${Component.classNameLoaded})`).forEach((el, i) => {

      let name = el.dataset.jsComponent;

      if (name in this.library) {
        try {
          el.classList.remove(this.classNameLoaded);
          el.instance = new this.library[name](el);
          if('onBoot' in el.instance.constructor && typeof el.instance.constructor['onBoot'] === 'function' && !Component.didBoot(this.library[name].cssClass)) {
            el.instance.constructor.onBoot();
          }
          el.classList.add(this.classNameLoaded);
        } catch (error) {
          console.error(error);
        }
      }

    });

  }

  static register(name, component) {
    return this.library[name] = component;
  }

  static didBoot(componentCssClass) {
    return document.querySelectorAll(`.${componentCssClass}.${Component.classNameLoaded}`).length > 0;
  }

}
