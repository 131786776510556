import Swiper, { EffectFade, Pagination, Autoplay } from "swiper";
import { Lazyload } from "../../assets/js/helpers/lazyload";

export class Slider {
  static cssClass = "c-slider";

  constructor($el = null) {
    // Configure Swiper to use modules
    Swiper.use([EffectFade, Pagination, Autoplay]);

    this.$el = $el;

    this.defaultConfig = {};

    // Creates the config JSON
    this.config = $el.dataset.config
      ? { ...this.defaultConfig, ...JSON.parse($el.dataset.config) }
      : this.defaultConfig;

    this.$wrapper = $el.querySelector(`.${Slider.cssClass}__wrapper`);
    this.$slider = $el.querySelector(`.${Slider.cssClass}__instance`);

    // Init Swiper
    this.slider = new Swiper(this.$slider, this.config);

    // Forces the load of the images inside the current slide
    this.slider.on("slideChange", (swiper) => {
      swiper.slides[swiper.activeIndex]
        .querySelectorAll('img[loading="lazy"]')
        .forEach((e) => Lazyload.forceLoad(e));
    });
  }
}
