export class Utils {

  static credits() {
    console.log(`%c
 ______     ______     __  __     __
/\\___  \\   /\\  __ \\   /\\ \\/ /    /\\ \\
\\/_/  /__  \\ \\  __ \\  \\ \\  _"-.  \\ \\ \\
  /\\_____\\  \\ \\_\\ \\_\\  \\ \\_\\ \\_\\  \\ \\_\\
  \\/_____/   \\/_/\\/_/   \\/_/\\/_/   \\/_/

    Creative Digital Agency - zaki.it\n`, "font-family:monospace");
  }

  static getNS(namespaceName = 'zaux') {
    return window[namespaceName] = window[namespaceName] || {};
  }

  static get isDebug() {
    return Utils.getNS().config.debug === true;
  }

}
